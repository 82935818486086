export function useToken() {
  return useAuth().token;
}

export function getAdminFilter(type) {
  const { data: admin } = useAuth();
  return admin.value?.filters.find(_filter => _filter.type === type);
}

export async function getClient() {
  const { data: admin } = useAuth();
  const client = useState("client");
  const clientId = useCookie("client");
  const { runtimeConfig } = useNuxtApp();

  if (!clientId.value) clientId.value = admin.value.client_id;

  if (!client.value) {
    const data = await $fetch(`${runtimeConfig.public.apiURL}/clients/${clientId.value}`, { headers: { Authorization: useToken().value } });
    if (data && data.success) client.value = data.client;
  }

  return client;
}

export async function getShop() {
  const { data: admin } = useAuth();
  const shop = useState("shop");
  const shopId = useCookie("shop");
  const { runtimeConfig } = useNuxtApp();

  if (!shopId.value) shopId.value = admin.value.shop_id;

  if (!shop.value) {
    const data = await $fetch(`${runtimeConfig.public.apiURL}/shops/${shopId.value}`, { headers: { Authorization: useToken().value } });
    if (data && data.success) shop.value = data.shop;
  }

  return shop;
}
