export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/settings`,
  async getOptions() {
    return await $fetch(`${this.apiURL}/options`, { headers: { Authorization: useToken().value } });
  },
  async getOptionsByClientId(id) {
    return await $fetch(`${this.apiURL}/options?client_id=${id}`, { headers: { Authorization: useToken().value } });
  },
  async saveOptionValue(optionValue) {
    return await $fetch(`${this.apiURL}/options`, {
      method: optionValue.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...optionValue },
    });
  },
  async deleteOptionValue(optionValue) {
    return await $fetch(`${this.apiURL}/options`, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: optionValue.id },
    });
  },
  async saveSort(sort) {
    return await $fetch(`${this.apiURL}/options/save-sort`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { type: "option_value", sort },
    });
  },
});
